import React from 'react'
import { Result } from 'antd'
import { Trans, useTranslation } from 'lib/i18n'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'

const Error = ({ statusCode }) => {
  const { t } = useTranslation()

  return (
    <Result
      status={statusCode}
      title={t('error.title', 'An error occured')}
      subTitle={
        <Trans i18nKey="error.subTitle">
          We have been notified about the error. Feel free to also contact us at{' '}
          <a href="mailto:support@memmo.me">support@memmo.me</a>
        </Trans>
      }
      extra={
        <Link href="/">
          <CtaButton>{t('error.goBack', 'Go back Home')}</CtaButton>
        </Link>
      }
    />
  )
}

export default Error
